import { Component, HostListener, inject, OnInit, PLATFORM_ID } from '@angular/core';
import { GalleryItem, ImageItem } from 'ng-gallery';
import { HttpClient } from '@angular/common/http';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { environment } from '../../environments/environment';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrl: './main.component.scss'
})
export class MainComponent implements OnInit {

  @HostListener('window:unload', [ '$event' ])

  private readonly title = inject(Title)
  private readonly meta = inject(Meta)
  private readonly route = inject(ActivatedRoute)
  private readonly platform = inject(PLATFORM_ID)
  private readonly document = inject(DOCUMENT)

  private _httpClient = inject(HttpClient)

  apiUrl = environment.apiUrl
  s3Url = environment.s3Url
  bookingUrl = environment.bookingUrl
  liffBooking = environment.liffBooking

  link: any
  linkName: any
  referralCode: any
  couponCode: any

  token: any

  hotel: any
  images: GalleryItem[] = []

  language: any = 'th'
  displaySidebar: boolean = false
  displayPolicy: boolean = false

  isMobile: boolean = false
  dialogBookingChanel: boolean = false

  constructor() {
    this.link = this.route.snapshot.paramMap.get('link')
    let coupon = this.route.snapshot.paramMap.get('coupon')

    if(this.platform == 'browser') {
      const urlParams = new URLSearchParams(window.location.search)
      this.token = urlParams.get('param4')
    }

    if(!this.link) {
      if(this.platform == 'browser') {
        window.location.href = "https://xpaxbooking.com/"
      }
      
    }

    let data = this.link.split("-", 2)
    this.linkName = data[0]
    if(data[1] != undefined) {
      this.referralCode = data[1]
    }else {
      this.referralCode = ""
    }

    if(coupon != undefined) {
      this.couponCode = coupon
    }else {
      this.couponCode = ""
    }

    if(this.checkMobile()) {
      this.isMobile = true
    }else {
      this.isMobile = false
    }
  }

  ngOnInit(): void {
    this.getHotelByLinkName(this.linkName).then((data) => {
      this.hotel = data
      if(this.hotel.title != null) {
        this.title.setTitle(this.hotel.hotel_name + " | " + this.hotel.title)
      }else {
        this.title.setTitle(this.hotel.hotel_name)
      }
      this.meta.addTag({ name: "description", content: this.hotel.seo_description })
      this.meta.addTag({ name: "keywords", content: this.hotel.keywords })
      this.meta.addTag({ name: "author", content: "XPAX BOOKING" })

      this.meta.addTag({ name: "robots", content: "noindex,nofollow" })
      this.meta.addTag({ name: "googlebot", content: "noindex,nofollow" })
      this.meta.addTag({ name: "bingbot", content: "noindex,nofollow" })

      this.meta.addTag({ property: "og:type", content: "website" })
      this.meta.addTag({ property: "og:url", content: this.bookingUrl + this.linkName })
      this.meta.addTag({ property: "og:image", content: this.s3Url + "MAIN_PICTURE_HOTEL/" + this.hotel.main_picture })
      this.meta.addTag({ property: "og:title", content: this.hotel.title })
      this.meta.addTag({ property: "og:description", content: this.hotel.seo_description })

      this.meta.addTag({ name: "twitter:card", content: "summary_large_image" })
      this.meta.addTag({ name: "twitter:title", content: this.hotel.title })
      this.meta.addTag({ name: "twitter:description", content: this.hotel.seo_description })
      this.meta.addTag({ name: "twitter:image", content: this.s3Url + "MAIN_PICTURE_HOTEL/" + this.hotel.main_picture })
      this.meta.addTag({ property: "twitter:domain", content: this.bookingUrl })
      this.meta.addTag({ property: "twitter:url", content: this.bookingUrl + this.linkName })

      let hotelGallery: any[] = []
      hotelGallery.push(
        new ImageItem({ src: this.s3Url + 'MAIN_PICTURE_HOTEL/' + this.hotel.main_picture, thumb: this.s3Url + 'MAIN_PICTURE_HOTEL/' + this.hotel.main_picture })
      )

      this.hotel.data_gallery.forEach((room :any) => {
        hotelGallery.push(
          new ImageItem({ src: this.s3Url + 'GALLERY_HOTEL_IMG/' + room.path_img, thumb: this.s3Url + 'GALLERY_HOTEL_IMG/thumb_' + room.path_img })
        )
      })

      this.images = hotelGallery
    }).catch((error) => {
      if(this.platform == 'browser') {
        window.location.href = "https://xpaxbooking.com/"
      }
    })
  }

  getHotelByLinkName(linkName: string) {
    return new Promise((resolve, reject) => {
      this._httpClient.get(`${this.apiUrl}getHotelByRefCode?link_name=${linkName}`).subscribe(
        (response: any) => {
          resolve(response.data)
        },
        (error) => {
          reject(error)
        }
      )

    })
  }

  toggleSidebar($event: any) {
    $event.stopPropagation()
    this.displaySidebar = !this.displaySidebar
  }

  changeLanguage(lang: string) {
    this.language = lang
    localStorage.setItem('language', lang)
  }

  clickViewPolicy(){
    this.displayPolicy = true
  }

  clickViewMap() {
    if(this.hotel.url_map) {
      if(this.platform == 'browser') {
        window.open(this.hotel.url_map, '_blank')
      }
    }else {
      alert('ไม่พบแผนที่')
    }
  }

  reloadPage() {
    if(this.platform == 'browser') {
      window.location.reload()
    }
  }

  checkMobile() {
    if(this.platform == 'browser') {
      const regex = /Mobi|Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i
      return regex.test(navigator.userAgent)
    }
    return false
  }

  disPlayDialogBookingChanel() {
    if(this.checkMobile()) {
      this.dialogBookingChanel = true
    }else {
      // Booking Web
      this.bookingWeb()
    }
  }

  bookingWeb() {
    let url = this.bookingUrl + this.linkName
    if(this.referralCode) {
      url += "-" + this.referralCode
    }
    if(this.couponCode) {
      url += "/" + this.couponCode
    }

    if(this.token) {
      url += "?param4=" + this.token
    }
    
    if(this.platform == 'browser') {
      window.location.href = url
    }
  } 

  bookingLine() {
    let url = 'https://line.me/R/app/' + this.liffBooking + '?param=' + this.linkName + '&param2=line'
    if(this.referralCode) {
      url += '&param3=' + this.referralCode
    }
    if(this.couponCode) {
      url += '&param5=' + this.couponCode
    }
    
    if(this.platform == 'browser') {
      window.location.href = url
    }
  }
}
